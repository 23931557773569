<template>
  <div class="m-10 bg-white rounded-md">
    <form
      @submit.prevent="editReport(this.id, 'Report updated successfully')"
      class="shadow space-y-8 divide-y divide-gray-200 z-10 p-5"
      method="POST"
    >
      <div class="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("reportEditTitle") }}
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              {{ $t("reportEditTitleDescription") }}
            </p>
          </div>

          <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label
                for="username"
                class="capitalize block text-sm font-medium text-gray-700"
              >
                {{ $t("name") }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm w-1/2">
                <input
                  v-model="this.form.nickname"
                  type="text"
                  name="username"
                  id="username"
                  required
                  class="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="pt-8">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("emailAddress") }}
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              {{ $t("emailAddressReportEditDescription") }}
            </p>
          </div>
          <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <div class="mt-1">
                <form class="flex" @submit.prevent="pushToEmails(email)">
                  <div class="w-1/2">
                    <label for="email-address" class="sr-only"
                      >Email address</label
                    >
                    <div class="relative">
                      <input
                        v-model="email"
                        type="email"
                        name="email-address"
                        id="email-address"
                        autocomplete="email"
                        required
                        :class="[
                          this.invalidEmail
                            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
                            : 'border-gray-300 placeholder-gray-400 ',
                          'shadow-sm block w-full sm:text-sm rounded-md',
                        ]"
                        placeholder="you@example.com"
                      />
                      <div
                        v-if="this.invalidEmail"
                        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <ExclamationCircleIcon
                          class="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <p
                      v-if="this.invalidEmail"
                      class="text-sm text-red-600"
                      id="email-error"
                    >
                      {{ $t("pbxReport.inputError") }}
                    </p>
                  </div>
                  <div class="mt-0 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                      type="submit"
                      class="capitalize w-full header__button border border-transparent rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium text-white"
                    >
                      <PlusIcon class="mr-1 h-5 w-5 " aria-hidden="true" />
                      {{ $t("add") }}
                    </button>
                  </div>
                </form>
              </div>
              <div class="py-1" v-show="form.email_addresses.length > 0">
                <div
                  v-for="email in form.email_addresses"
                  :key="email"
                  class="inline-flex mb-1"
                >
                  <div class="mx-1">
                    <span
                      class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-600"
                    >
                      {{ email }}
                      <button
                        @click="removeEmailFromList(email)"
                        type="button"
                        class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-700 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
                      >
                        <span class="sr-only">Remove option</span>
                        <svg
                          class="h-2 w-2"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 8 8"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-width="1.5"
                            d="M1 1l6 6m0-6L1 7"
                          />
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-8">
          <div class="grid grid-cols-8">
            <SwitchGroup
              as="div"
              class="flex items-center justify-between col-span-3"
            >
              <span class="flex-grow flex flex-col">
                <SwitchLabel
                  as="span"
                  class="capitalize text-lg leading-6 font-medium text-gray-900"
                  passive
                  >{{ $t("schedule") }}</SwitchLabel
                >
                <SwitchDescription as="span" class="mt-1 text-sm text-gray-500">
                  {{ $t("scheduleReportEditDescription") }}
                </SwitchDescription>
              </span>
              <Switch
                v-model="enabled"
                :class="[
                  enabled ? 'encom_primary' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none encom_ring_color',
                ]"
              >
                <span class="sr-only">Use setting</span>
                <span
                  :class="[
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ]"
                >
                  <span
                    :class="[
                      enabled
                        ? 'opacity-0 ease-out duration-100'
                        : 'opacity-100 ease-in duration-200',
                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                    ]"
                    aria-hidden="true"
                  >
                    <svg
                      class="h-3 w-3 text-gray-400"
                      fill="none"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    :class="[
                      enabled
                        ? 'opacity-100 ease-in duration-200'
                        : 'opacity-0 ease-out duration-100',
                      'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                    ]"
                    aria-hidden="true"
                  >
                    <svg
                      class="h-3 w-3 encom_primary_text"
                      fill="currentColor"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                      />
                    </svg>
                  </span>
                </span>
              </Switch>
            </SwitchGroup>
          </div>
          <div v-if="enabled" class="mt-6">
            <div class="mt-4 space-y-4">
              {{ this.form.cron }}
              <RadioGroup v-model="selected">
                <RadioGroupLabel class="sr-only">
                  Privacy setting
                </RadioGroupLabel>
                <div class="bg-white rounded-md -space-y-px">
                  <RadioGroupOption
                    as="template"
                    v-for="(setting, settingIdx) in settings"
                    :key="setting.name"
                    :value="setting.value"
                    v-slot="{ checked }"
                  >
                    <div
                      :class="[
                        settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        settingIdx === settings.length - 1
                          ? 'rounded-bl-md rounded-br-md'
                          : '',
                        checked
                          ? 'encom_primary_light encom_primary_thin_border z-10'
                          : 'border-gray-200',
                        'relative border p-4 flex cursor-pointer focus:outline-none',
                      ]"
                    >
                      <span
                        :class="[
                          checked
                            ? 'body__button border-transparent'
                            : 'bg-white border-gray-300',
                          'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                        ]"
                        aria-hidden="true"
                      >
                        <span class="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <div class="ml-3 flex flex-col">
                        <RadioGroupLabel
                          as="span"
                          :class="[
                            checked
                              ? 'body__table--body__text'
                              : 'text-gray-900',
                            'block text-sm font-medium',
                          ]"
                        >
                          {{ $t(setting.name) }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                          as="span"
                          :class="[
                            checked
                              ? 'body__table-header-filter--text'
                              : 'text-gray-500',
                            'block text-sm',
                          ]"
                        >
                          {{ $t(setting.description) }}
                        </RadioGroupDescription>
                      </div>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end z-100">
          <a
            @click="$emit('closeEditForm')"
            href="javascript:void(0)"
            class="rounded z-100 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            {{ $t("ticket.cancel") }}
          </a>
          <button
            type="submit"
            class="rounded z-100 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium  body__table--header text-white"
          >
            {{ $t("ticket.send") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { PlusIcon } from "@heroicons/vue/solid";
import { ref } from "vue";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Switch,
} from "@headlessui/vue";
import QuickSearch from "../QuickSearch.vue";

const account = localStorage.getItem("account");
const settings = [
  {
    name: "daily",
    description: "cronDaily",
    value: "0 4 * * *",
  },
  {
    name: "weekly",
    description: "cronWeekly",
    value: "0 4 * * 1",
  },
  {
    name: "monthly",
    description: "cronMonthly",
    value: "0 4 1 * *",
  },
];
export default {
  props: ["name", "id", "parameters", "emails", "cron", "urlType"],
  components: {
    QuickSearch,
    PlusIcon,
    Switch,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },
  setup() {
    const enabled = ref(false);
    const selected = ref(settings[0]);

    return {
      enabled,
      settings,
      selected,
    };
  },
  data() {
    return {
      account,
      email: "",
      form: {
        nickname: "",
        cron: "",
        email_addresses: [],
      },
      hostsNames: [],
      invalidEmail: false,
      messageToShow: "",
      selectHostName: "",
      showMessage: false,
      subMessageToShow: "",
    };
  },
  methods: {
    setUpParams() {
      if (this.enabled) {
        this.form.cron = this.selected;
      } else {
        this.form.cron = "";
      }
    },
    async editReport(id, msg) {
      this.setUpParams();
      console.log(this.form.cron);
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/presentations/${id}`,
        data: {
          nickname: this.form.nickname,
          email_addresses: `${this.form.email_addresses}`,
          cron: this.form.cron,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((res) => {
          console.log(res);
          this.closePutForm = !this.closePutForm;
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch((err) => {
          console.error(err);
          this.errorHandling(err);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", err.response.data.message);
        });
    },
    pushEmailIntoArray() {
      let array = this.emails.split(",");
      this.form.email_addresses = array;
    },
    pushToEmails(email) {
      this.invalidEmail = false;

      if (this.validateEmail(email) && !this.emails.includes(email)) {
        this.form.email_addresses.push(email);
        this.email = "";
      } else {
        this.invalidEmail = true;
      }
    },
    removeEmailFromList(email) {
      let index = this.form.email_addresses.indexOf(email);
      this.form.email_addresses.splice(index, 1);
    },
    validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.form.nickname = this.name;
    this.pushEmailIntoArray();
  },
  watch: {
    name: function(val) {
      this.form.nickname = val;
    },
  },
};
</script>

<style></style>
